<template>
<div>
    <form-wizard color="#7367F0" :title="null" :subtitle="null" finish-button-text="Submit" back-button-text="Previous" class="steps-transparent mb-3" @on-complete="formSubmitted">
        <!-- Survey Details tab -->
        <tab-content title="Survey Details" icon="feather icon-file-text">
            <b-row>
                <b-col cols="12" class="mb-2">
                    <h5 class="mb-0">
                        Survey Details
                    </h5>
                    <small class="text-muted">
                        Enter Survey Details.
                    </small>
                </b-col>
                <b-col md="6">
                    <b-form-group label="Survey Name" label-for="i-survey-name">
                        <b-form-input id="i-survey-name" placeholder="Customer Feedback" v-model="surveyName" required />
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group label="Survey" label-for="survey">
                        <v-select id="survey" v-model="selectedSurvey" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="purposeSurvey" :selectable="option => ! option.value.includes('Customer_Satisfaction')" label="text" required />
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group label="Start Date" label-for="i-start-date">
                        <b-form-datepicker id="startDate" placeholder="Choose start date" local="en" :date-disabled-fn="dateDisabled" v-model="startDate" />

                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group label="End Date" label-for="i-end-date">
                        <b-form-datepicker id="endDate" placeholder="Choose end date" local="en" :date-disabled-fn="dateDisabled" v-model="endDate" />

                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group label="Channel" label-for="channel">
                        <v-select id="channel" v-model="selectedChannel" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="activeChannel" label="text" required />
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group label="ShortCode" label-for="shortcode">
                        <v-select id="shortcode" v-model="selectedShortcode" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="shortcodeoptions" label="text" required />
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group label="Select Recipient " label-for="recipient">
                        <v-select v-model="contacts" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" multiple label="name" class="flex-grow-1 email-cc-selector" :close-on-select="false" :options="contactOption" input-id="email-cc">

                            <template #option="{ avatar, name }">
                                <b-avatar size="sm" :src="avatar" />
                                <span class="ml-50"> {{ name }}</span>
                            </template>

                            <template #selected-option="{ avatar, name }">
                                <b-avatar size="sm" class="border border-white" :src="avatar" />
                                <span class="ml-50"> {{ name }}</span>
                            </template>
                        </v-select>
                    </b-form-group>
                </b-col>
            </b-row>
        </tab-content>

        <!-- Questions details -->
        <tab-content title="Question" icon="feather icon-list">
            <b-row>
                <b-col cols="12" class="mb-2">
                    <h5 class="mb-0">
                        Questions
                    </h5>
                    <small class="text-muted">Enter question to ask.</small>
                </b-col>
                <b-col cols="12" class="mb-2">
                    <question-repeat-form ref="questions" />
                </b-col>
            </b-row>
        </tab-content>

        <!-- Settings -->
        <tab-content title="Settings" icon="feather icon-settings">
            <b-row>
                <b-col cols="12" class="mb-2">
                    <h5 class="mb-0">
                        Survey Settings
                    </h5>
                    <small class="text-muted">Enter More settings</small>
                </b-col>

                <b-col md="2">
                    <b-form-group label="Auto Reminder" label-for="i-survey-name">

                        <b-form-checkbox checked="false" v-model="autoReminder" class="custom-control-primary" name="check-button" switch />
                    </b-form-group>
                </b-col>
                <b-col md="3">
                    <b-form-group label="Reminder Period" label-for="period" v-show="autoReminder">
                        <v-select id="period" v-model="reminderPeriod" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="reminderType" label="text" />
                    </b-form-group>
                </b-col>
                <b-col md="7">
                    <b-form-group label="Reminder Message" label-for="message" v-show="autoReminder">
                        <b-form-textarea id="textarea-plaintext" placeholder="Enter Auto reminder Message" rows="3" v-model="reminderMessage" />
                    </b-form-group>
                </b-col>

                <b-col md="6">
                    <b-form-group label="Optin Message" label-for="message">
                        <b-form-textarea id="textarea-plaintext" placeholder="Enter Optin  Message" rows="3" v-model="Optin" required />
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group label="Optout Message" label-for="message">
                        <b-form-textarea id="textarea-plaintext" placeholder="Enter Optout  Message" rows="3" v-model="Optout" required />
                    </b-form-group>
                </b-col>

                <b-col md="6">
                    <b-form-group label="Save Point" label-for="i-survey-name">
                        <b-form-checkbox checked="true" v-model="enableSavePoint" class="custom-control-primary" name="check-button" switch />
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group label="Whitelist" label-for="i-survey-name">
                        <b-form-checkbox checked="false" v-model="enableWhitelist" class="custom-control-primary" name="check-button" switch />
                    </b-form-group>
                </b-col>

                <b-col md="4">
                    <b-form-group label="Reward" label-for="i-survey-name">
                        <b-form-checkbox checked="false" v-model="enableReward" class="custom-control-primary" name="check-button" switch />
                    </b-form-group>
                </b-col>
                <b-col md="4">
                    <b-form-group label="Reward Type" label-for="reward" v-show="enableReward">
                        <v-select id="reward" v-model="selectedIncentive" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="surveyRewardList" label="text" />
                    </b-form-group>
                </b-col>
                <b-col md="4">
                    <b-form-group label="Reward Type" label-for="reward" v-show="enableReward">
                        <v-select id="reward" v-model="selectedUtility" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="utilityList" label="text" />
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group label="Incentive Amount" label-for="amount" v-show="enableReward">
                        <cleave id="amount" v-model="rewardAmount" class="form-control" :raw="false" :options="number" placeholder="100" />
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group label="Incentive Limit" label-for="limit" v-show="enableReward">
                        <cleave id="limit" v-model="mechanicLimit" class="form-control" :raw="false" :options="number" placeholder="1,000" />
                    </b-form-group>
                </b-col>

            </b-row>
        </tab-content>
        <tab-content title="Confirm" icon="feather icon-check">
        
            <b-row>
                <b-col cols="12" class="mb-2">
                    <h5 class="mb-0">
                        Survey Details
                    </h5>
                </b-col>
                <b-col md="6">
                    <b-form-group label="Survey Name" label-for="i-survey-name">
                        <b-form-input id="i-survey-name" placeholder="Customer Feedback" v-model="surveyName" disabled />
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group label="Survey" label-for="survey">
                        <v-select id="survey" v-model="selectedSurvey" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="purposeSurvey" :selectable="option => ! option.value.includes('Customer_Satisfaction')" label="text" disabled />
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group label="Start Date" label-for="i-start-date">
                        <b-form-datepicker id="startDate" placeholder="Choose start date" local="en" :date-disabled-fn="dateDisabled" v-model="startDate" disabled />

                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group label="End Date" label-for="i-end-date">
                        <b-form-datepicker id="endDate" placeholder="Choose end date" local="en" :date-disabled-fn="dateDisabled" v-model="endDate" disabled />

                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group label="Channel" label-for="channel">
                        <v-select id="channel" v-model="selectedChannel" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="activeChannel" label="text" />
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group label="Select Recipient " label-for="recipient">
                        <v-select v-model="contacts" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" multiple label="name" class="flex-grow-1 email-cc-selector" :close-on-select="false" :options="contactOption" input-id="email-cc" disabled>

                            <template #option="{ avatar, name }">
                                <b-avatar size="sm" :src="avatar" />
                                <span class="ml-50"> {{ name }}</span>
                            </template>

                            <template #selected-option="{ avatar, name }">
                                <b-avatar size="sm" class="border border-white" :src="avatar" />
                                <span class="ml-50"> {{ name }}</span>
                            </template>
                        </v-select>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" class="mb-2">
                    <h5 class="mb-0">
                        Survey Settings
                    </h5>
                    <small class="text-muted">Enter More settings</small>
                </b-col>

                <b-col md="2">
                    <b-form-group label="Auto Reminder" label-for="i-survey-name">

                        <b-form-checkbox checked="false" v-model="autoReminder" class="custom-control-primary" name="check-button" switch disabled />
                    </b-form-group>
                </b-col>
                <b-col md="3">
                    <b-form-group label="Reminder Period" label-for="period" v-show="autoReminder">
                        <v-select id="period" v-model="reminderPeriod" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="reminderType" label="text" disabled />
                    </b-form-group>
                </b-col>
                <b-col md="7">
                    <b-form-group label="Reminder Message" label-for="message" v-show="autoReminder">
                        <b-form-textarea id="textarea-plaintext" placeholder="Enter Auto reminder Message" rows="3" v-model="reminderMessage" disabled />
                    </b-form-group>
                </b-col>

                <b-col md="6">
                    <b-form-group label="Optin Message" label-for="message">
                        <b-form-textarea id="textarea-plaintext" placeholder="Enter Optin  Message" rows="3" v-model="Optin" disabled />
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group label="Optout Message" label-for="message">
                        <b-form-textarea id="textarea-plaintext" placeholder="Enter Optout  Message" rows="3" v-model="Optout" disabled />
                    </b-form-group>
                </b-col>

                <b-col md="6">
                    <b-form-group label="Save Point" label-for="i-survey-name">
                        <b-form-checkbox checked="true" v-model="enableSavePoint" class="custom-control-primary" name="check-button" switch />
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group label="Whitelist" label-for="i-survey-name">
                        <b-form-checkbox checked="false" v-model="enableWhitelist" class="custom-control-primary" name="check-button" switch disabled />
                    </b-form-group>
                </b-col>

                <b-col md="4">
                    <b-form-group label="Reward" label-for="i-survey-name">
                        <b-form-checkbox checked="false" v-model="enableReward" class="custom-control-primary" name="check-button" switch disabled />
                    </b-form-group>
                </b-col>
                <b-col md="4">
                    <b-form-group label="Reward Type" label-for="reward" v-show="enableReward">
                        <v-select id="reward" v-model="selectedIncentive" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="surveyRewardList" label="text" disabled />
                    </b-form-group>
                </b-col>
                <b-col md="4">
                    <b-form-group label="Reward Type" label-for="reward" v-show="enableReward">
                        <v-select id="reward" v-model="selectedUtility" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="utilityList" label="text" disabled />
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group label="Incentive Amount" label-for="amount" v-show="enableReward">
                        <cleave id="amount" v-model="rewardAmount" class="form-control" :raw="false" :options="number" placeholder="100" disabled />
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group label="Incentive Limit" label-for="limit" v-show="enableReward">
                        <cleave id="limit" v-model="mechanicLimit" class="form-control" :raw="false" :options="number" placeholder="1,000" disabled />
                    </b-form-group>
                </b-col>

            </b-row>
            <b-row>
                <b-col cols="12" class="mb-2">
                    <h5 class="mb-0">
                        Questions
                    </h5>
                </b-col>
                <b-col cols="12" class="mb-2">

                    <!-- Row Loop -->
                    <b-row v-for="(item, index) in questionsData" :id="item.id" :key="item.id + index" ref="row">

                        <!-- Item Name -->
                        <b-col md="12">
                            <b-form-group label="Question" label-for="question">
                                <b-form-input id="question" type="text" placeholder="Type your question here" v-model="item.question" />
                            </b-form-group>
                        </b-col>

                        <!-- Question Type -->
                        <b-col md="4">
                            <b-form-group label="Question Type" label-for="question-type">
                                <v-select id="question-type" v-model="item.selectedQuestion" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="text" disabled />
                            </b-form-group>

                        </b-col>

                        <!-- Answer -->
                        <b-col md="6" v-if="item.selectedQuestion">
                            <b-form-group label="Question" label-for="question" v-show="item.selectedQuestion.value == '3'">
                                <b-form-input id="question" type="text" placeholder="Single Select" />
                            </b-form-group>
                            <b-form-group label="Message" label-for="message" v-show="item.selectedQuestion.value == '11'">
                                <b-form-textarea id="textarea-plaintext" placeholder="The the client will enter message" rows="3" :value="item.message" disabled />
                            </b-form-group>
                            <b-form-group label="Yes/No" label-for="yes-no" v-show="item.selectedQuestion.value == '10'">

                                <b-form-input id="yes" type="text" placeholder="Yes" value="Yes" disabled />

                                <b-form-input id="no" type="text" placeholder="No" value="No" disabled />
                                <!-- Choice Repeat-->

                            </b-form-group>
                            <!-- <OptionChoiceRepeat v-show="item.selectedQuestion.value == 'choices'" v-model="item.choices" /> -->
                            <b-form-group label="Choices" label-for="checoice" v-show="item.selectedQuestion.value == '4'">
                                <b-form-tags v-model="item.options" input-id="tags-state-event" placeholder="Enter Option separated by Commas" separator="," disabled/>
                            </b-form-group>

                            <b-form-group label="Rating" label-for="rate" v-show="item.selectedQuestion.value == '1'">
                                <v-select id="rate" v-model="item.rate" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"  label="text" disabled />

                            </b-form-group>
                        </b-col>

                        <!-- Remove Button -->
                        <b-col cols="12">
                            <hr>
                        </b-col>
                    </b-row>
                    <b-alert v-height-fade.appear :show="dismissCountDown" dismissible fade variant="danger" class="mb-0" @dismiss-count-down="countDownChanged">
                <div class="alert-body">
                    <span>{{errorMessage}}</span>
                </div>
            </b-alert>

                </b-col>
            </b-row>
        </tab-content>

    </form-wizard>

</div>
</template>

<script>
import {
    FormWizard,
    TabContent
} from 'vue-form-wizard'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import QuestionRepeatForm from './QuestionRepeatForm.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import $ from 'jquery'
import store from '@/store'
import Cleave from 'vue-cleave-component'
import Ripple from 'vue-ripple-directive'
import {
    heightFade
} from '@core/directives/animations'
import {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    BFormTextarea,
    BFormCheckbox,
    BAvatar,
    BFormTags,
    BSpinner,
    BAlert,
} from 'bootstrap-vue'

export default {
    components: {
        FormWizard,
        TabContent,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormDatepicker,
        vSelect,
        // eslint-disable-next-line vue/no-unused-components
        ToastificationContent,
        QuestionRepeatForm,
        BFormTextarea,
        BFormCheckbox,
        Cleave,
        BAvatar,
        BFormTags,
        BSpinner,
    BAlert,
    },
    data() {
        return {
            selectedContry: 'select_value',
            selectedSurvey: 'Customer_Satisfaction',
            reminderPeriod: '24',
            purposeSurvey: [{
                    value: 'Customer_Satisfaction',
                    text: 'Customer Satisfaction'
                },
                {
                    value: 'Data_Collection',
                    text: 'Data Collection'
                },
                {
                    value: 'Product_Reviews',
                    text: 'Product Reviews'
                },
            ],
            reminderType: [{
                    value: '24',
                    text: '24 Hrs'
                },
                {
                    value: '12',
                    text: '12 Hrs'
                },
            ],
            surveyName: "",
            startDate: "",
            endDate: "",
            activeChannel: [],
            cost: 0,
            currency: "",
            selectedChannel: "",
            questionsData: [],
            autoReminder: false,
            reminderMessage: "",
            Optin: "",
            Optout: "",
            enableSavePoint: true,
            enableWhitelist: true,
            enableReward: false,
            surveyRewardList: [],
            selectedIncentive: "",
            utilityList: [{
                text: "Airtime",
                value: 1,
            }],
            rewardAmount: 0,
            mechanicLimit: 0,
            number: {
                numeral: true,
                numeralThousandsGroupStyle: 'thousand',
            },
            contacts: [],
            contactOption: [],
            selectedUtility: "",
            shortcodeoptions:[],
            selectedShortcode:"",
            dismissSecs: 5,
            dismissCountDown: 0,
            showDismissibleAlert: false,
            errorMessage: "",

        }
    },
    mounted() {
        let vm = this;
        this.$watch(
            "$refs.questions.items",
            (new_value, old_value) => {
                //execute your code here
                vm.questionsData = new_value
            }
        );
        this.getSurveySettings()
        this.getSurveyRewardsTypes()
        this.getContacts()
        this.getAllsenderIDs()
    },
 directives: {
        Ripple,
        heightFade
    },
    methods: {
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown
        },

        dateDisabled(ymd, date) {

            var today = new Date();
            // Return `true` if the date is less than today
            return date < today
        },
        getSurveyRewardsTypes() {
            let vm = this
            //clientData
            $.get({
                url: store.state.rootUrl + '/survey/v1/view/incentives',
                type: "get",
                async: true,
                crossDomain: true,
                headers: {
                    "Content-Type": "application/json",
                },
                success: function (response, status, jQxhr) {
                    vm.surveyRewardList
                    if (response.data.code != 200) {
                        vm.surveyRewardList = []
                    } else {
                        var result = response.data.data.data
                        for (let i = 1; i < result.length; i++) {
                            var rewardType = {
                                text: result[i].incentive,
                                value: result[i].id,
                            }
                            vm.surveyRewardList.push(rewardType)

                        }
                    }
                },
                error: function (jQxhr, status, error) {}
            })
        },
        getSurveySettings() {
            let vm = this
            //clientData
            $.get({
                url: store.state.rootUrl + '/survey/v1/view/charges',
                type: "get",
                async: true,
                crossDomain: true,
                headers: {
                    "Content-Type": "application/json",
                },
                success: function (response, status, jQxhr) {

                    if (response.data.code != 200) {} else {
                        let result = response.data.data.active_channels;
                        for (let i = 1; i < result.length; i++) {
                            var channels = {
                                text: result[i].value,
                                value: result[i].id,
                            }
                            vm.activeChannel.push(channels)

                        }
                        vm.cost = response.data.data.costs.new_creation
                        vm.currency = response.data.data.costs.currency

                    }

                },
                error: function (jQxhr, status, error) {}
            })
        },
        getContacts() {
            let vm = this
            $.get({
                url: store.state.rootUrl + 'contact/v1/view/groups',
                type: "get",
                async: true,
                crossDomain: true,
                headers: {
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    "X-Authorization-Key": vm.$cookies.get("token"),
                },
                data: {
                    limit: 100
                },
                success: function (response, status, jQxhr) {

                    $('#resetPWD').html(' Reset Password');
                    if (response.data.code != 200) {

                    } else {
                        let result = response.data.data;
                        for (var i = 0; i < result.length; i++) {
                            if (result[i].status == 1) {
                                var contact = {
                                    avatar: "",
                                    value: result[i].list_id,
                                    name: result[i].group_name,

                                }
                                vm.contactOption.push(contact)
                            }
                        }
                    }
                },
                error: function (jQxhr, status, error) {
                    if (jQxhr.responseJSON.data.code == 401 || jQxhr.responseJSON.data.code == 500 || jQxhr.responseJSON.data.code == 403 || jQxhr.responseJSON.data.code == 404) {
                        vm.$cookies.remove("token");
                        vm.$cookies.remove("clientData");
                        // Redirect to login page
                        vm.$router.push({
                            name: 'login'
                        })
                        vm.$toast({
                            component: ToastificationContent,
                            props: {
                                title: jQxhr.responseJSON.data.message,
                                icon: 'EditIcon',
                                variant: 'failed',
                            },
                        })
                    }
                }
            });
        },
         getAllsenderIDs() {
            let vm = this
            $.get({
                url: store.state.rootUrl + 'account/v1/view/sender_ids',
                type: "get",
                async: true,
                data: {
                    typeId: 4,
                    shortCode: "",
                    offset: "",
                    sort: "",
                    export: "",
                    limit: "",
                    start: "",
                    end: "",
                    clientId: vm.$cookies.get("clientData").clientId
                },
                crossDomain: true,
                headers: {
                    "Content-Type": "application/json",
                    "X-Authorization-Key": vm.$cookies.get("token"),
                    "X-Requested-With": "XMLHttpRequest",
                },
                success: function (response, status, jQxhr) {

                    $('#resetPWD').html(' Reset Password');
                    if (response.data.code != 200) {
                        vm.rows = []
                    } else {
                        let result = response.data.data;
                        for (var i = 0; i < result.length; i++) {
                            //if (result[i].status == 1) {
                                var contact = {
                                    value: result[i].short_code,
                                    text: result[i].short_code,

                                }
                                
                            //} 
                            vm.shortcodeoptions.push(contact)
                        }

                    }

                },
                error: function (jQxhr, status, error) {
                    vm.rows = []
                }
            });
        },
        formSubmitted() {
            let vm = this
            console.log(JSON.stringify(this.$refs.questions.items))
            var listIDs = ""
            var arrayQuestion = []
            var questionDataList = this.$refs.questions.items;
            questionDataList.forEach((item, index) => {
                var questionChoicesArray = []
                if (item.selectedQuestion.value == 10) {
                    questionChoicesArray = [{
                            "value": "YES",
                            "numbering": "1",
                            "linkQuestionId": ""
                        },
                        {
                            "value": "NO",
                            "numbering": "2",
                            "linkQuestionId": ""
                        }
                    ]
                }
                if (item.selectedQuestion.value == 4) {
                    let count = 1
                    item.options.forEach((option, index) => {
                        var optionData = {
                            "value": option,
                            "numbering": count,
                            "linkQuestionId": ""
                        }
                        count++
                        questionChoicesArray.push(optionData)
                    })
                }
                var questionData = {
                    "question": item.question,
                    "questionTypeId": item.selectedQuestion.value,
                    "questionChoices": questionChoicesArray
                }
                arrayQuestion.push(questionData)
            })
            vm.contacts.forEach((contact, index) => {
                if (index == 0) {
                    listIDs = contact.value
                } else {
                    listIDs = listIDs + "," + contact.value
                }

            });
            var SurveyDetails = {
                "surveyName": vm.surveyName,
                "surveyDesc": vm.surveyName,
                "contactLists": listIDs,
                "surveyMode": {
                    "channelMode": vm.selectedChannel.value,
                    "channelSource": vm.selectedShortcode.value
                },
                "autoReminder": vm.autoReminder,
                "enableSavePoint": vm.enableSavePoint,
                "enableWhitelist": vm.enableWhitelist,
                "reminderPeriod": vm.reminderPeriod.value,
                "optinMessage": vm.Optin,
                "optoutMessage": vm.Optout,
                "reminderMessage": vm.reminderMessage,
                "countryCode": "254",
                "startDate": vm.startDate +" 00:00:00",
                "stopDate": vm.endDate +" 11:59:59"
            }
            var questionnaire = {
                "questionnareName": vm.surveyName + "Questionnnaire",
                "questionnareDesc": vm.surveyName + "Test Questions",
                "questionnareQuestions": arrayQuestion
            }
            var incentiveData = null
            if (vm.enableReward) {
                incentiveData = {
                    "incentiveType": vm.selectedUtility.value,
                    "utilityId": "1",
                    "rewardAmount": vm.rewardAmount,
                    "rewardName": "AIRTIME KES." + vm.rewardAmount + "/-",
                    "mechanicLimit": vm.mechanicLimit
                }
            }

            $.ajax({
                url: store.state.rootUrl + 'survey/v1/create',
                type: "POST",
                data: JSON.stringify({
                    "survey": SurveyDetails,
                    "questionnaire": questionnaire,
                    "incentive": incentiveData,
                }),
                headers: {
                    "X-Authorization-Key": vm.$cookies.get("token"),
                    "X-Requested-With": "XMLHttpRequest",
                },
                cache: false,
                contentType: false,
                processData: false,
                success: function (response, status, jQxhr) {

                    if (response.data.code != 200) {
                        // console.log("error message " + response.data.message)
                        vm.dismissCountDown = vm.dismissSecs
                        vm.showDismissibleAlert = true
                        vm.errorMessage = response.data.message

                    } else {
                        vm.$toast({
                            component: ToastificationContent,
                            props: {
                                title: response.data.message,
                                icon: 'EditIcon',
                                variant: 'success',
                            },
                        })
                        vm.$router.push({
                            name: 'survey-dashboard'
                        });
                    }

                },
                error: function (jQxhr, status, error) {
                    vm.isloading = false
                    vm.dismissCountDown = vm.dismissSecs
                    if (jQxhr.responseJSON.data.code == 401 || jQxhr.responseJSON.data.code == 500 || jQxhr.responseJSON.data.code == 403 || jQxhr.responseJSON.data.code == 404) {
                        vm.$cookies.remove("token");
                        vm.$cookies.remove("clientData");
                        // Redirect to login page
                        vm.$router.push({
                            name: 'login'
                        })
                        vm.$toast({
                            component: ToastificationContent,
                            props: {
                                title: jQxhr.responseJSON.data.message,
                                icon: 'EditIcon',
                                variant: 'failed',
                            },
                        })
                    }

                    var statustext = jQxhr.responseJSON.statusDescription;
                    vm.showDismissibleAlert = true;
                    if (!statustext) {
                        vm.errorMessage = "An error occurred. Try again later.";
                    } else {
                        if (jQxhr.responseJSON.data) {
                            vm.errorMessage = statustext + " " + jQxhr.responseJSON.data.message;
                        } else {
                            vm.errorMessage = statustext
                        }

                    }
                }
            });

        },
    },
}
</script>
