<template>
  <b-row>
    <b-col cols="12">
      <survey-wizard-form/>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import SurveyWizardForm from './SurveyWizardForm.vue'


export default {
  components: {
    BRow,
    BCol,
    SurveyWizardForm
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
