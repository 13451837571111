<template>
<div style="overflow:auto">
    <b-form ref="form" :style="{height: trHeight}" style="overflow: auto;transition: 0.35s height;" @submit.prevent="repeateAgain">

        <!-- Row Loop -->
        <b-row v-for="(item, index) in items" :id="item.id" :key="item.id" ref="row">

            <!-- Item Name -->
            <b-col md="12">
                <b-form-group label="Question" label-for="question">
                    <b-form-input id="question" type="text" placeholder="Type your question here" v-model="item.question" />
                </b-form-group>
            </b-col>

            <!-- Question Type -->
            <b-col md="4">
                <b-form-group label="Question Type" label-for="question-type">
                    <v-select id="question-type" v-model="item.selectedQuestion" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="questionType" label="text" />
                </b-form-group>

            </b-col>

            <!-- Answer -->
            <b-col md="6" v-if="item.selectedQuestion">
            <b-form-group label="Question" label-for="question" v-show="item.selectedQuestion.value == '3'">
                    <b-form-input id="question" type="text" placeholder="Single Select"  />
                </b-form-group>
                <b-form-group label="Message" label-for="message" v-show="item.selectedQuestion.value == '11'">
                    <b-form-textarea id="textarea-plaintext" placeholder="The the client will enter message" rows="3" :value="item.message" disabled />
                </b-form-group>
                <b-form-group label="Yes/No" label-for="yes-no" v-show="item.selectedQuestion.value == '10'">

                    <b-form-input id="yes" type="text" placeholder="Yes" value="Yes" disabled />

                    <b-form-input id="no" type="text" placeholder="No" value="No" disabled />
                    <!-- Choice Repeat-->

                </b-form-group>
                <!-- <OptionChoiceRepeat v-show="item.selectedQuestion.value == 'choices'" v-model="item.choices" /> -->
                <b-form-group label="Choices" label-for="checoice" v-show="item.selectedQuestion.value == '4'" >
                <b-form-tags v-model="item.options" input-id="tags-state-event"  placeholder="Enter Option separated by Commas" separator="," />
                </b-form-group>
                
                <b-form-group label="Rating" label-for="rate" v-show="item.selectedQuestion.value == '1'">
                    <v-select id="rate" v-model="item.rate" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="ratingOptions" label="text" />

                </b-form-group>
            </b-col>

            <!-- Remove Button -->
            <b-col lg="2" md="3" class="mb-50">
                <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger" class="mt-0 mt-md-2" @click="removeItem(index)">
                    <feather-icon icon="XIcon" class="mr-25" />
                    <span>Delete</span>
                </b-button>
            </b-col>
            <b-col cols="12">
                <hr>
            </b-col>
        </b-row>

    </b-form>
    <br />
    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="repeateAgain">
        <feather-icon icon="PlusIcon" class="mr-25" />
        <span>Add New</span>
    </b-button>

</div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BButton,
    BFormTextarea,
    BFormRating,
     BFormTags,
} from 'bootstrap-vue'
import {
    heightTransition
} from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import OptionChoiceRepeat from '@/views/survey/create/OptionChoiceRepeat'
import $ from 'jquery'
import store from '@/store'

export default {
    components: {
        BCardCode,
        BForm,
        BRow,
        BCol,
        BButton,
        BFormGroup,
        BFormInput,
        vSelect,
        BFormTextarea,
        OptionChoiceRepeat,
        BFormRating,
        BFormTags,
    },
    directives: {
        Ripple,
    },
    mixins: [heightTransition],
    data() {
        return {
            items: [{
                id: 1,
                question: "",
                selectedQuestion: {},
                options: [],
                message: '',
                rate: {},
                choices: []
            }],
            choiceOptions: null,
            nextTodoId: 2,
            selectedQuestion: 'text',
            ratingOptions: [{
                    value: '5',
                    text: '5 Rating'
                },
                {
                    value: '10',
                    text: '10 Rating'
                }
            ],
            questionType: [

            ],

        }
    },
    mounted() {
        this.initTrHeight()
        this.getSurveyQuestionType()

    },
    created() {
        window.addEventListener('resize', this.initTrHeight)
    },
    destroyed() {
        window.removeEventListener('resize', this.initTrHeight)
    },
    watch: {
        items: function (val) {
            //do something when the data changes.
            if (val) {
                this.$cookies.set("QuestionData", val);
                console.log(JSON.stringify(val))
            }
        },
        choiceOptions: function (val) {
            console.log("Option Data " + JSON.stringify(val))
        }
    },
    methods: {
        convertJson(value) {
            // preserve newlines, etc - use valid JSON
            value = value.replace(/\\n/g, "\\n")
                .replace(/\\'/g, "\\'")
                .replace(/\\"/g, '\\"')
                .replace(/\\&/g, "\\&")
                .replace(/\\r/g, "\\r")
                .replace(/\\t/g, "\\t")
                .replace(/\\b/g, "\\b")
                .replace(/\\f/g, "\\f");
            // remove non-printable and other non-valid JSON chars
            value = value.replace(/[\u0000-\u0019]+/g, "");
            return JSON.parse(value)
        },

        getSurveyQuestionType() {
            let vm = this
            //clientData
            $.get({
                url: store.state.rootUrl + '/survey/v1/view/question_types',
                type: "get",
                async: true,
                crossDomain: true,
                headers: {
                    "Content-Type": "application/json",
                },
                success: function (response, status, jQxhr) {

                    if (response.data.code != 200) {
                        vm.questionType = []
                    } else {
                        let result = response.data.data.data;
                        for (let i = 1; i < result.length; i++) {
                            var questionTypeArray = {
                                text: result[i].question_type,
                                value: result[i].id,
                            }
                            vm.questionType.push(questionTypeArray)

                        }

                    }

                },
                error: function (jQxhr, status, error) {}
            })
        },

        repeateAgain() {
            this.items.push({
                id: this.nextTodoId += this.nextTodoId,
            })

            this.$nextTick(() => {
                this.trAddHeight(this.$refs.row[0].offsetHeight)
            })
        },
        removeItem(index) {
            this.items.splice(index, 1)
            this.trTrimHeight(this.$refs.row[0].offsetHeight)
        },
        initTrHeight() {
            this.trSetHeight(null)
            this.$nextTick(() => {
                this.trSetHeight(this.$refs.form.scrollHeight)
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.repeater-form {
    overflow: hidden;
    transition: .35s height;
}
</style>
